@tailwind base;
@tailwind components;
@tailwind utilities;

.loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
    transform: scale(1.05)
  }
  50% {
    opacity: .2;
    transform: scale(1)
  }
}